































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { PageHeader } from "@/components/al/pageHeader";
import { Form, FormItem, Dialog } from "element-ui";
import AlFormItem from "@/components/sign/FormItemBox.vue";
import signjs from "@/components/sign/index";
@Component({
    components: {
        "al-page-header": PageHeader,
        "el-form": Form,
        "al-form-item": AlFormItem,
        "el-form-item": FormItem,
    },
})
export default class AssessForm extends Vue {
    @Prop({ type: Array, required: true, default: () => [] })
    formOpt: any;

    @Prop({ type: Object, required: false, default: () => { } })
    formRules: any;

    @Prop({ type: Object, required: true, default: () => { } })
    formData: any;

    @Prop({ type: String, required: true, default: () => "" })
    record_id: any;

    childName = "assessForm"; //表单名ref

    subbtnLoading: boolean = false; //提交按钮是否loading

    isFinished: boolean = this.$route.params.isFinished == "1" ? true : false; //是否评审过

    /**总分 */
    get totalScore() {
        let formKeyArr: any[] = [];
        this.formOpt.forEach((item: any, index: any) => {
            if (item.type == "radio") formKeyArr.push(item.formkey);
        });
        let formData = this.formData;
        let fina_score: number = 0;
        for (const key in formData) {
            if (formData.hasOwnProperty(key)) {
                const element = formData[key];
                if (formKeyArr.findIndex((val: any) => val == key) != -1)
                    fina_score = fina_score + Number(this.formData[key]);
            }
        }

        return fina_score;
    }

    firstEnter: boolean = true; //是否第一次进入组件

    /**监听总分变化
     * 弹窗提示分数变化 */
    @Watch("totalScore")
    totalScoreChange(nv: any, ov: any) {
        if (this.firstEnter) {
            this.firstEnter = !this.firstEnter;
        } else {
            // this.$message(`${this.$t("scaffold.assess.nowScore")}:${nv}`);
        }
    }

    dialogVisible = false;

    // 表单单项验证
    validItem(formname: string, formItem: string | object, callback: any) {
        signjs
            .validateFormItem(formname, formItem, this.$refs)
            .then((res: any) => {
                callback(res);
            });
    }

    //提交评审
    onSubmit(formName?: any) {
        this.dialogVisible = false;
        signjs.validateForm(formName, this.$refs).then((res: any) => {
            if (res) {
                this.subbtnLoading = true;
                const getOpt = this.$api.apiList.uploadScore;
                const opt = getOpt(this.record_id);
                const data = this.beforeSub(this.formData);

                this.$axios({
                    url: opt.url,
                    method: opt.method,
                    headers: opt.headers,
                    data: data,
                }).then((res: any) => {
                    // console.log(res);
                    if (res.data.data == true) {
                        this.$notify({
                            title: `${this.$t("scaffold.success")}`,
                            message: `${this.$t("scaffold.submit_success")}`,
                            type: "success",
                        });
                        this.$router.back();
                    } else {
                        this.$notify({
                            title: `${this.$t("scaffold.error")}`,
                            message: `${this.$t("scaffold.submit_error")}`,
                            type: "error",
                        });
                    }
                    this.subbtnLoading = false;
                });
            }
        });
    }

    beforeSub(data: any) {
        const setData = (data: any) => {
            const _data = {
                ...this.formData,
                ...{
                    form_field: this.formData,
                    fina_score: this.totalScore,
                    form_key: Object.keys(this.formData),
                },
            };

            return _data;
        };

        const res = setData(data);
        return res;
    }
}
